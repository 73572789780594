import React, { useEffect, useState } from "react";
import {useSelector} from "react-redux";
import TestimonialSlider from "./TestimonialSlider";
import axios from "axios";
import { configuration } from "../../helpers/config";
import { toast } from "react-toastify";
import { Constants } from "../../helpers/constants";

const TestimonialSliderContainer = (props) => {
  const {pageType} = props;
  const [testimonialData, setTestimonialData] = useState([]);
  let LOB_VALUE=useSelector(state=>state?.app?.LobValue);
  if(LOB_VALUE == null)
  {
    LOB_VALUE = 1;
  }
  const reduxData = useSelector(({ app }) => app);
 
  useEffect(() => {
    if (!LOB_VALUE) return; // Prevent API call if LOB_VALUE is null or undefined

    if (reduxData.dealerCode != "") {
      (async () => {
        try {
          if(LOB_VALUE!==null){
          const res = await axios.get(
            `${configuration.APP_URL_CONTENTS}/testimonial?lob=${LOB_VALUE}&dealer_code=${reduxData.dealerCode}`,
          );
          // Filter out testimonials with status equal to "1"
          const filteredTestimonials = Array.isArray(res.data)
          ? res.data.filter((testimonial) => testimonial.status !== 1)
          : [];   
          setTestimonialData(filteredTestimonials);
        }
        } catch (e) {
          console.log(Constants.testimonial.ERROR_FETCHING);
        }
      })();
    }
  }, [reduxData.dealerCode, LOB_VALUE]);

  return (
    <div>
      {testimonialData && testimonialData?.length > 0 && (
        <TestimonialSlider showHeading={true} data={testimonialData} pageType={pageType}/>
      )}
    </div>
  );
};

export default TestimonialSliderContainer;
